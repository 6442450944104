<template>
  <div class="chart" ref="chart" />
</template>

<script>
import Highcharts from 'highcharts'
import { tooltipOptions } from '../../../gmb/locations/insights/helpers'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    metric: {
      type: String,
      required: true
    }
  },
  created() {
    const averageRankDataFormatter = (value) => {
      const formattedValue = parseFloat(value)
      if (Number.isNaN(formattedValue)) {
        return {
          y: 21.0,
          marker: {
            enabled: false
          }
        }
      }
      return {
        y: formattedValue,
        marker: {
          enabled: false
        }
      }
    }
    const averageRankChartConfig = {
      yAxis: {
        gridLineWidth: 0,
        title: {
          enabled: false
        },
        labels: {
          enabled: false
        },
        reversed: true,
        tickPositioner() {
          return [0, 21]
        }
      }
    }

    this.dataFormatters = {
      agr: averageRankDataFormatter,
      atgr: averageRankDataFormatter,
      solv: (y) => ({
        y: y ? Math.round(parseFloat(y).toFixed(2) * 100) : 0,
        marker: {
          enabled: false
        }
      })
    }

    this.metricConfigs = {
      atgr: averageRankChartConfig,
      agr: averageRankChartConfig,
      solv: {
        yAxis: {
          gridLineWidth: 0,
          title: {
            enabled: false
          },
          labels: {
            enabled: false
          },
          tickPositioner() {
            return [0, 100]
          }
        }
      }
    }

    this.tooltipFormatters = {
      atgr: (y) => (y < 21 ? y.toFixed(2) : 21),
      agr: (y) => (y < 21 ? y.toFixed(2) : 'N/A'),
      solv: (y) => `${y || 0}%`
    }
  },
  methods: {
    drawChart() {
      // I'm not entirely sure if its necessary to destroy previously drawn chart.
      // It works either way.

      if (this.chart) {
        this.chart.destroy()
      }

      const { metric } = this
      const formatter = this.dataFormatters[metric]
      const tooltipFormatter = this.tooltipFormatters[metric]
      const metricConfig = this.metricConfigs[metric]
      const dataset = this.data.map(formatter)
      dataset[dataset.length - 1].marker.enabled = true

      const color = 'rgb(55, 125, 255)'
      this.chart = Highcharts.chart(this.$refs.chart, {
        alignTicks: false,
        allowDecimals: false,
        credits: { enabled: false },
        chart: {
          width: 86,
          height: 40,
          spacing: 4,
          backgroundColor: 'transparent',
          style: {
            display: 'block'
          },
          type: 'area'
        },
        title: false,
        legend: { enabled: false },
        subtitle: { enabled: false },
        xAxis: {
          type: 'linear',
          visible: false,
          crosshair: {
            width: 8,
            color: `${color}30`
          }
        },
        time: { useUTC: false },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            threshold: metric === 'solv' ? 0 : 21
          },
          line: {
            marker: {
              radius: 2,
              fillColor: color
            }
          }
        },
        series: [{
          data: dataset,
          color: '#377DFF',
          fillColor: {
            linearGradient: [0, 0, 0, 21],
            stops: [
              [0, Highcharts.color('#377DFF').setOpacity(0.25).get('rgba')],
              [
                1,
                Highcharts.color('#377DFF').setOpacity(0.05).get('rgba')
              ]
            ]
          }
        }],
        tooltip: {
          ...tooltipOptions,
          formatter() {
            return `<div class="p-2 text-uppercase">${metric}: <span class="ranks">${tooltipFormatter(this.y)}</span></div>`
          }
        },
        ...metricConfig
      })
    }
  },
  watch: {
    data: {
      handler() {
        setTimeout(this.drawChart)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
